@import 'styles/variable.less';

.mainHeaderRow {
  color: @white;
  justify-content: space-between;
  align-items: center;
  .mainLogoCol {
    img {
      width: 90px;
    }
  }
  .mainTitleCol {
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 10px;
    > img {
      max-width: 150px;
      object-fit: contain;
      max-height: 50px;
    }
  }
  .mainIconCol {
    text-align: end;
    > button {
      margin-right: 10px;
      &:hover {
        background-color: @white;
        border-radius: 100%;
        .tooltipIcon {
          color: @primary;
        }
      }
    }
    .tooltipIcon {
      font-size: 18px;
      color: @white;
      vertical-align: -2px;
    }
  }
}

.tooltipTitle {
  color: @greyShade !important;
  padding: 3px 10px !important;
  margin: 0 !important;
}
.user-menu-item {
  padding: 5px 15px;
}
.menuTitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  > svg {
    margin-right: 13px;
  }
  > span {
    color: @greyShade !important;
    font-weight: 500;
    font-size: 14px;
  }
}
.menu-title {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  > span {
    margin-right: 7px;
    background-color: @primary;
    width: 42px;
    height: 42px;
    line-height: 42px;
  }
  padding-bottom: 5px;
  border-bottom: 1px solid @greyShade;
  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    > span {
      color: @primary;
    }
    > span:first-child {
      font-weight: 500;
      font-size: 16px;
    }
    > span:last-child {
      font-weight: 400;
      font-size: 14px;
    }
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;