@import 'styles/variable.less';

.mainOrganizationContent {
  background-color: @white;
  > div {
    > div {
      > div {
        > span {
          color: @titleColor !important;
          text-shadow: none !important;
          font-weight: 600;
        }
      }
    }
  }
  .topContentBtn {
    background-color: @white;
    color: @primary;
    text-shadow: none;
    box-shadow: none;
    &:hover {
      background-color: @primary;
      color: @white;
    }
  }
}
.boxesRowMain {
  height: calc(100vh - 158px);
  overflow-y: auto;
  margin: 0 20px 0px 20px !important;
  border: 1px solid @primary;
  border-radius: 4px;
  padding: 10px;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: @veryLightGray;
    border-radius: 3px;
  }
  .boxesRow {
    margin: 0 !important;
    .mainBox {
      margin: 0px;
      padding: 0px !important;
      position: relative;
      &:hover {
        .edit-org {
          display: block;
        }
      }
      .boxes {
        cursor: pointer;
        &:hover {
          border: none;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        }
        padding: 10px 20px;
        border: 1px solid @lightGray;
        border-radius: 4px;
        margin: 10px;
        text-align: center;
        @media (max-width: 1330px) {
          padding: 8px 18px;
          margin: 8px;
        }
        @media (max-width: 1100px) {
          padding: 3px 11px;
          margin: 8px;
          padding-top: 6px;
        }
        > span {
          color: @primary;
          text-transform: uppercase;
          font-size: 14px;
          font-weight: 600;
          text-overflow: ellipsis;
          max-width: 280px !important;
          white-space: nowrap;
          display: inline-block;
          overflow: hidden;
          @media (max-width: 1330px) {
            font-size: 14px;
            max-width: 250px !important;
          }
          @media (max-width: 1250px) {
            font-size: 12px;
            max-width: 220px !important;
          }
          @media (max-width: 1100px) {
            max-width: 200px !important;
          }
        }
        .imgOfLogo {
          height: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          > img {
            max-width: 65%;
            height: 70px;
            object-fit: contain;
          }
        }
        .projectsAndNotification {
          .projects {
            color: @primary;
            margin-right: 10px;
            font-size: 14px;
            @media (max-width: 1330px) {
              margin-right: 8px;
            }
            @media (max-width: 1100px) {
              margin-right: 3px;
            }
            > span {
              margin-left: 10px;
              background-color: @veryLightGray;
              color: @blackShade2;
              padding: 5px 10px;
              border-radius: 2px;
              @media (max-width: 1330px) {
                margin-left: 7px;
              }
              @media (max-width: 1100px) {
                margin-left: 7px;
                font-size: 12px;
                 padding: 3px 8px;
              }
            }
          }
          .notification {
            color: @notification;
            margin: 0 10px;
            font-size: 16px;
            @media (max-width: 1330px) {
              margin: 0 3px;
              font-size: 14px;
            }
            > span {
              color: @notification;
            }
          }
          .line {
            font-size: 25px;
          }
        }
      }
      .staticBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 49px 20px;
        border: 1px solid @lightGray;
        border-radius: 4px;
        margin: 10px;
        cursor: pointer;
        @media (max-width: 1330px) {
          padding: 43px 18px;
        }
        @media (max-width: 1100px) {
          padding: 37.5px 18px;
        }
        &:hover {
          border: none;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        }
        .pluseIcon {
          width: 50px;
          height: 50px;
          border-radius: 100%;
          background-color: @veryLightGray;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 25px;
          margin-bottom: 20px;
        }
        .staticText {
          text-transform: uppercase;
          color: @btnTitle;
          font-weight: 600;
          text-align: center;
        }
      }
      .edit-org {
        display: none;
        position: absolute;
        top: 10px;
        right: 10px;
        background: @darkGray;
        height: 35px;
        width: 35px;
        border-radius: 0px 4px;
        padding-left: 8px;
        padding-top: 5px;
        z-index: 9999;
        cursor: pointer;
      }
    }
  }
}
.org-title {
  margin: 0 !important;
  padding: 0 !important;
}

.getNotification {
  color: @red !important;
  > span {
    color: @red !important;
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;